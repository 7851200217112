import { Box, Typography, useMediaQuery, Link } from '@mui/material';

const labels = [
  { label: 'Responsible Gambling' },
  { label: '18+' },
  { label: 'Gambling Therapy', to: 'https://www.gamblingtherapy.org/' },
];

const Footer = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <Box
      component="footer"
      sx={{
        bgcolor: '#000',
        py: 3,
        width: '80%',
        margin: '0 auto',
        textAlign: 'center',
      }}
    >
      <Typography
        variant="subtitle1"
        align="center"
        color="white"
        component="p"
      >
        © 2024 Coqbook. All rights reserved.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 1,
          flexDirection: isMobile ? 'column' : 'row',
          mt: 2,
        }}
      >
        {labels.map((label, index) => (
          <Typography
            key={index}
            variant="body2"
            align="center"
            color="white"
            component="span"
            sx={{ mt: 0.25 }}
          >
            {label.to ? (
              <Link
                href={label.to}
                target="_blank"
                rel="noreferrer"
                sx={{
                  color: 'inherit',
                  textDecoration: 'none',
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                {label.label}
              </Link>
            ) : (
              label.label
            )}
            {!isMobile && index !== labels.length - 1 ? ' | ' : ''}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default Footer;
